import { Fragment, useState, useEffect } from "react";
import { useFormik } from 'formik';
import {useParams, useNavigate} from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import * as Yup from 'yup';
import Datepicker from "react-tailwindcss-datepicker";
import Moment from 'moment';
import Loading from '../../../Components/Loading';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Input,
  Button,
  Typography,
  Dialog,
  DialogFooter,
  Radio
} from "@material-tailwind/react";
import {getAdminUrl} from "../../../helpers/Utils";
import TopNav from '../../../Components/TopNav/TopNav';
import AuthUser from '../../../helpers/AuthUser';
import CustomSelect from '../../../helpers/CustomSelect';
import toast from 'react-hot-toast';
import {fncalculationswithgiftcard} from '../../../helpers/checkoutCalculations';
import {statusOption, optionstime, paidstatusOption} from '../../../helpers/appointmentOptions';
 
function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

function EditAppointmentForm() {
    // const [paymentError, setPaymentError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // const [error,setError] = useState(false);
    // const [done,setDone] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const navigate = useNavigate();
    const {http, getStudents, getClients,getSpecialNeedOptions, user} = AuthUser();
    const {id} = useParams();

    const [openAcc1, setOpenAcc1] = useState(true);
    const [openAcc2, setOpenAcc2] = useState(true);
    const [openAcc3, setOpenAcc3] = useState(true);

    const [dialogopen, setDialog] = useState(false);
    const [dialogopenstudent, setDialogStudent] = useState(false);
    const [dialogopendelete, setDialogDelete] = useState(false);
    const [dialogopenstudenterror, setDialogStudentError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [AppointmentOrders, setAppointmentOrders] = useState([]);
    const [data_client, setData_1] = useState([]);
    const [data_st, setData_Student] = useState([]);
    const [data_status, setStatus] = useState([]);
    const [data_paid, setPaidStatus] = useState([]);
    const [data_massage, setMassage] = useState([]);
    const [data_notes, setNotes] = useState([]);
    const [data_clientnotes, setClientNotes] = useState([]);
    const [data_time, setTime] = useState([]);
    // const [data_vaccinated, setvaccinated] = useState([]);
    // const [data_need, setNeed] = useState([]);
    const [selectedDate, setDate] = useState(null)
    const [show, setShow] = useState(false)
    const [service, setService] = useState([]);
    const [users, setUsers] = useState([]);
    const [specialneed, SpecialNeeds] = useState([]);
    const [clients, setClients] = useState([]);
    const [userId, setselectedClient] = useState(null);
    const [Voucher, SetVoucher] = useState(null);

    const [SelectedService, setServiceAmount] = useState(null);
    const [balance, SetCurrentBalance] = useState(null);
    // const [message, setMessage] = useState(null);
    const [pendingBalance, setPendingBalance] = useState(0);
    const [cancelledFees, setcancelledFees] = useState(0);
    // const [isCancelled, setIsCancelled] = useState(false);
    const [CancelledOption, setCancelOption] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [paymentlabel, setPaymentLabel] = useState(null);

    const handleClose = (state) => {
        setShow(state)
    }

    const handleOpenAcc1 = () => setOpenAcc1((cur) => !cur);
    const handleOpenAcc2 = () => setOpenAcc2((cur) => !cur);
    const handleOpenAcc3 = () => setOpenAcc3((cur) => !cur);

    const fetchSelectedClientDetails = async (userID) => {
        try {
          const response = await http.get(`auth/user/${userID}`);
           setFieldValue('status','Booked');
           setFieldValue('specialneeds',response.data.data.special_id);
        } catch (error) {
          console.log(error);
        }
    };


    const handleClientChange =(selectedClient) =>{
        setData_1({});
        setFieldValue('client',parseInt(selectedClient.value));
        setselectedClient(parseInt(selectedClient.value));
        fetchPendingBalance(selectedClient.value);
        fetchSelectedClientDetails(selectedClient.value)
    }

    const handleclientnoteChange =(selectedClientNote) =>{
         setClientNotes({});
        setFieldValue('specialneeds',parseInt(selectedClientNote.value));
    }

    const handleCancelOption =(selectedCancelOption) => {
        setCancelOption(selectedCancelOption.target.value)
    }

    const fetchSelectedStudentDetails = async (appointmentId, studentId) => {
        try {
            const response = await http.put(`appointment/${appointmentId}/updateStudentData`, {
                student_id: studentId,
            });
            if(response.data.status === true && response.data.status === true){
                setDialogStudent(true);
            }else{
                setDialogStudentError(true);
            }
            } catch (error) {
            setDialogStudentError(true);
        }
    };

    const handleStudentChange = async (selected) => {
        setData_Student(selected.value);
        setFieldValue('student',selected.value);
        await fetchSelectedStudentDetails(id,selected.value);
    }

    const customAnimation = {
        mount: { opacity: 1 },
        unmount: { opacity: 0 },
    };

    useEffect(() => {
        UsersGet()
        ServiceGet()
        GetNeeds()
        async function fetchData() {
            setIsLoading(true);
            try {
                const response =  await http.get(`appointment/${id}`);
                const data = await response.data.data;
                setAppointmentOrders(data.appointment_orders);
                setData(data); setData_1(data); setData_Student(data);
                setStatus(data); setPaidStatus(data); setMassage(data);
                setTime(data); 
                //setvaccinated(data); 
                //setNeed(data);
                setNotes(data);
                setClientNotes(data);
                setDate(Moment(data.appointment_date).format('DD/MM/YYYY'));
                setServiceAmount(data.promo_price?data.promo_price:data.service_price)
                const fields = ['id', 'student', 'client', 'status', 'paid', 'massage_type', 'time', 'is_vaccinated', 'notes'];
                fields.forEach(field => setFieldValue(field, data[field], false));
                setIsLoading(false);
            } catch (error) {
                console.error("An error occured to fetch the student details.");
            }
        } 
      fetchData();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchPendingBalance = async (userID) => {
        try {
          const response = await http.get(`/user/${userID}/pending-balance`);
           const  pendingBalance  = response.data;
           setPendingBalance(pendingBalance.balance);
           setcancelledFees(pendingBalance.cancelledFees);
        } catch (error) {
          console.log('Error');
        }
    };

    const UsersGet = () => {
     getStudents({per_page:50000}).then((resp)=>{
        setUsers(resp.data.data);
     });
     getClients({per_page:50000}).then((resp)=>{
        setClients(resp.data.data);
     });
    }

    const GetNeeds = () => {  
        getSpecialNeedOptions().then((resp)=>{
           SpecialNeeds(resp.data.data);
        });
     }
    const ServiceGet = () => {
        http.get('auth/services').then((resp)=>{
            setService(resp.data.data);
        });  
    }

    const clientOptions = clients.map(d => ({
      "value" : d.id,
      "label" : d.first_name + ' ' + d.last_name  + ' (' + d.email + ') '
    }))

    const studentOptions = users.map(d => ({
      "value" : d.id,
      "label" : d.first_name + ' ' + d.last_name  + ' (' + d.email + ') '
    }))

    const specialneeds = specialneed.map(option => ({
        "value" : option.id,
        "label" : option.title
      }))

    const massageOption = service.map(ser => ({
        "value" : ser.id, 
        "label" : ser.title,
        "price":  ser.sale_price?ser.sale_price:ser.price
    }))
    const handleChange = (selectedDate) => {
        setDate(Moment(selectedDate.startDate).format('DD/MM/YYYY'))
    }
    const dialogHandleOpen = () => setDialog(!dialogopen);
    const dialogHandleDeleteOpen = () => setDialogDelete(!dialogopendelete);
    
    
    const openDialogDelete = () => {
        setDialogDelete(true);
    }
    const openDialog = () => {
        setDialog(true);
    }
    const closeDialog = () => {
        setDialog(!handleOpenAcc1);
        setLoading(false);            
    }

    const closeDialogDelete = () => {
        setDialogDelete(false);
    }
    const closeDialogStudent = () => {
        setDialogStudent(false);
    }

    const closeDialogStudentError = () => {
        setDialogStudentError(false);
    }

    const topNav = [
        {
            name: "View All",
            url: getAdminUrl('appointments')
        },
        {
            name: "Remedial/Deep Tissue",
            url: getAdminUrl('appointments/remedial')
        },
        {
            name: "Myotherapy/Advanced Treatment",
            url: getAdminUrl('appointments/myotherapy')
        },
        {
            name: "Relaxation/Basic Treatment",
            url: getAdminUrl('appointments/relaxation')
        },
        ...(user.user_type_primary === 'admin'
        ? [
        {
            name: "Book Appointment",
            url: getAdminUrl('add-appointment'),
            button: [
                {
                    name: "Book Appointment"
                }
            ]
        }
        ]
        : []),
    ]

    const [isGiftCardUsed, setIsGiftCardUsed] = useState(false);

    const _fncalculationsresults =  () => {
        let ServiceAmount       = parseInt(SelectedService?SelectedService:0.00);
    
        let GiftCardBalance      = parseInt(balance?balance:'0.00');
        let AppliedCredit        = parseInt(pendingBalance?pendingBalance:'0.00');
        let Cancellation_Fee     = parseInt(cancelledFees?cancelledFees:'0.00');
        let RemainingGiftamount  = '0.00';
        let RemainingApplCredit  = '0.00';
        let UsedGiftamount       = '0.00';
        let TotalServicePrice    = '0.00';
        let TotalAmountService   = '0.00';
        let SelectedServicePrice = ServiceAmount;
        return fncalculationswithgiftcard(SelectedServicePrice,Cancellation_Fee, AppliedCredit, GiftCardBalance,RemainingGiftamount,TotalServicePrice,UsedGiftamount,RemainingApplCredit,TotalAmountService);
    };

    const results = _fncalculationsresults();
    let TotalPrice1 = parseFloat(results.SelectedServicePrice).toFixed(2);
    let applied_credit1 = parseFloat(results.AppliedCredit).toFixed(2);
    useEffect(() => {
          if (applied_credit1 >= TotalPrice1) {
                setPaidStatus('Applied Credit');
                setFieldValue('paid', 'Applied Credit');
                setSelectedValue("Applied Credit");
                setPaymentLabel("Applied Credit");
            }else if (data_status.status === 'Available'){
             setPaidStatus('Awaiting Payment');
             setFieldValue('paid', 'Awaiting Payment');
             setSelectedValue("Awaiting Payment");
             setPaymentLabel("Awaiting Payment");
         }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applied_credit1, TotalPrice1]);    
    
    const handlePaymentStatus = async (selectedStatus) => {
            fetchPendingBalance(data_client.client);
            setPaidStatus({});
            setFieldValue('paid',selectedStatus.value);
            setSelectedValue(selectedStatus.value);
            setPaymentLabel(selectedStatus.label);
    }


    const makePaymentRequest = async (allformData)=>{
        await http.put(`appointment/${id}`,JSON.stringify(allformData, null, 2)).then((response)=>{
            // setError(false)
            // setDone(true)
            if(response.data.status === true){
                toast.success(response.data.message)
                setLoading(false)
                // navigate('/admin/appointments')
                navigate(-1);
            }else{
                toast.error(response.data.message) 
                setLoading(false)
            }
            return response;
        }).catch(error => {
        //    setError(true)
           //toast.error("Error with payment response. Please try again!");
           toast.error("Error with appointment booking. Please fill enter coorect details and try to submit again!");
        });
    }
    
    const { handleSubmit, handleBlur, errors, touched, getFieldProps, setFieldValue,values } = useFormik({
        initialValues: {
            student: '',
        },
        validationSchema: Yup.object({
            //client: Yup.string().nullable().required('Please select a client.'),
        }),
        onSubmit: async (values) => {
           
            let cartTotal         = parseFloat(results.TotalServicePrice).toFixed(2);
            let TotalServicePrice = parseFloat(results.TotalAmountService).toFixed(2);
            let voucheramount     = parseFloat(results.UsedGiftamount).toFixed(2);
            let applied_credit    = parseFloat(results.AppliedCredit).toFixed(2);
            let Cancellation_Fees = parseFloat(results.Cancellation_Fee).toFixed(2);
            // setLoading(true);
             if(data.paid === "Credit Card" || data.paid === "Gift Card"){
                const allFormData = {
                    ...values,
                    amount:cartTotal,
                    totalserviceprice:TotalServicePrice,
                    paymentstatusCode:"updateData"
                }
               await makePaymentRequest(allFormData);
                return;
             }else if(values.paid === "Awaiting Payment" || values.paid === "Payment Required"){
                const allFormData = {
                    ...values,
                    amount:cartTotal,
                    totalserviceprice:TotalServicePrice,
                    giftcard:voucheramount,
                    applied_credit:applied_credit,
                    Cancellation_Fees:Cancellation_Fees,
                    paymentMethod:'',
                    stripeToken:'',
                }
               await makePaymentRequest(allFormData);
               return;
            }else if(values.paid === 'Cash' || values.paid === "EFT" || values.paid === "Staff" || values.paid === "Free" ){
                const allFormData = {
                    ...values,
                    amount:cartTotal,
                    totalserviceprice:TotalServicePrice,
                    giftcard:voucheramount,
                    applied_credit:applied_credit,
                    Cancellation_Fees:Cancellation_Fees,
                    paymentMethod:'',
                    stripeToken:'',
                }
               await makePaymentRequest(allFormData);
               return;
            }else if (parseFloat(cartTotal) <= 0 || parseFloat(cartTotal) === 0.00 ) {
                const allFormData = {
                    ...values,
                    amount:cartTotal,
                    totalserviceprice:TotalServicePrice,
                    giftcard:voucheramount,
                    applied_credit:applied_credit,
                    Cancellation_Fees:Cancellation_Fees,
                    paymentMethod:'',
                    stripeToken:'',
                 }
                await makePaymentRequest(allFormData);
                return;
            }
           
        if (!stripe || (!elements && cartTotal > 0)) {
            setLoading(false);
            return;
        }
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
        });
        const stripeToken = await stripe.createToken(cardElement);
        if (error) {
         //  setPaymentError(error.message);
           setLoading(false);
           return;
        }
        const allFormData = {
            ...values,
            stripeToken:stripeToken.token.id,
            amount:cartTotal,
            totalserviceprice:TotalServicePrice,
            giftcard:voucheramount,
            applied_credit:applied_credit,
            Cancellation_Fees:Cancellation_Fees,
            payment_method:paymentMethod.id,
         }
        await makePaymentRequest(allFormData);
          handleUpdate(allFormData);
      }
    });

    const CancelStatus = getFieldProps('status').value;
    const paidValue = getFieldProps('paid').value;

    const handleSearch = async (event) => {
        SetVoucher(null);
        const code1 = getFieldProps('gift_card').value;
        if(code1){
          let parms = {
            code: code1,
          };
        await http.post('/redeemVoucher',parms).then((resp)=>{
            SetVoucher(resp.data);
            SetCurrentBalance(resp.data.balance);
            setIsGiftCardUsed(true);
       }).catch(err => {
           SetVoucher('');
        //    setMessage(err.response);
           setIsGiftCardUsed(false);
        });
        }
    }
    const handleUpdate = async (allValues) => {
       if(allValues.student !=null){
        setLoading(true);
        http.put(`appointment/${id}/update`,allValues).then((result)=>{
           if (result.data['status'] === true) {
               toast.success(result.data['message']);
               setLoading(false);
               navigate(-1);
           }else{
               toast.error(result.data['message']);
               setLoading(false);
               navigate(-1);
          }
        }).catch(err => {
            toast.error(err);
        });
    }else{
        toast.error('Please Assign student for this appointment')
        return false;
     }
    };
    const handleComplete = async () => {
        setLoading(true);
        http.put(`appointment/${id}/complete`).then((result)=>{
            if (result.data['status'] === true) {
               toast.success(result.data['message']);
               setLoading(false);
               navigate(-1);
            }else{
                toast.error(result.data['message']);
                setLoading(false);
                navigate(-1);
           }
        }).catch(err => {
            toast.error(err);
        });
    };

    const handleCancellation = () => {
        setLoading(true);        
        let cancellationFees = 0;
        if(CancelledOption === "No"){
            cancellationFees = 0;
        }else{
            cancellationFees = 15;
        }
        // setIsCancelled(true);
        http.post('/cancel-booking', { appointmentId: id, cancellationFee: cancellationFees, appointmentStatus:CancelStatus,cancelStatus:CancelledOption })
          .then(response => {
            setLoading(false);            
            if(response.data.status === false){
                setDialog(false);
                setLoading(false);            
                toast.error(response.data['message']);
            } else {
                setDialog(false);
                setLoading(false);            
                navigate('/admin/appointments');
                toast.success(response.data['message']);
            }
          })
          .catch(error => {
             toast.error("Error with cancellation process.");
          });
    };

    const handleDelete = (event,appointmentID) => {
        event.preventDefault();            
        http.delete(`appointment/${appointmentID}/delete`)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/admin/appointments");
          setDialog(false);
        }).catch((error) => {
            if (error.response) {
               toast.error(error.response?.data?.message);
             } else {
              toast.error('An error occurred.');
           }
        });
    }
  

    let TotalServicePaid = 0;

    if(AppointmentOrders){
        if(parseFloat(AppointmentOrders.gift_price) > 0  && parseFloat(AppointmentOrders.wallet_credit) > 0 && parseFloat(AppointmentOrders.stripe_price) > 0 ){
            TotalServicePaid = parseFloat(AppointmentOrders.stripe_price)+parseFloat(AppointmentOrders.wallet_credit)+ parseFloat(AppointmentOrders.gift_price)
        }else if(parseFloat(AppointmentOrders.stripe_price) && parseFloat(AppointmentOrders.wallet_credit) ){
            TotalServicePaid = parseFloat(AppointmentOrders.stripe_price)+parseFloat(AppointmentOrders.wallet_credit);
        }else if(parseFloat(AppointmentOrders.gift_price) > 0  && parseFloat(AppointmentOrders.stripe_price) > 0) {
            TotalServicePaid = parseFloat(AppointmentOrders.stripe_price)+parseFloat(AppointmentOrders.gift_price);
        }else if(parseFloat(AppointmentOrders.gift_price) > 0  && parseFloat(AppointmentOrders.wallet_credit) > 0){
            TotalServicePaid = parseFloat(AppointmentOrders.gift_price)+parseFloat(AppointmentOrders.wallet_credit);
        }else if(parseFloat(AppointmentOrders.gift_price)){
            TotalServicePaid = parseFloat(AppointmentOrders.gift_price);
        }else if(parseFloat(AppointmentOrders.stripe_price)){
            TotalServicePaid = parseFloat(AppointmentOrders.stripe_price);
        }else{
            TotalServicePaid = parseFloat(AppointmentOrders.price);
        }
    }

    let PaidStatus;
    if(data_paid.paid === "Gift Card"){
         PaidStatus = "Credit Card"
    }else{
        PaidStatus = data_paid.paid
    }

    const clientnotes = getFieldProps('specialneeds').value;
    return (
        <> 
        {isLoading && <Loading /> }
        {loading &&<Loading /> }
        <TopNav data={topNav} />
        <div className="white-box">
       
         <form className="custom-form" onSubmit={handleSubmit}>
            <Fragment>

                <Accordion open={openAcc1} icon={<Icon id={1} open={openAcc1} />} animate={customAnimation} className="accordion-main">
                    <AccordionHeader onClick={handleOpenAcc1} className="accordion-heading">
                    Edit Appointment
                    </AccordionHeader>
                    <AccordionBody className="accordion-body">
                        
                        <div className="flex gap-6">
                            <div className="form-select w-3/12 mb-4 relative mt-6">
                                <label>Client</label>
                                <CustomSelect
                                    name="client"
                                    isDisabled={data_client.client !== null && userId === null ? true : false}
                                    className='input'
                                    onChange={handleClientChange}
                                    onBlur={handleBlur}
                                    value={parseInt(data_client.client)}
                                    options={clientOptions}
                                    placeholder="Search Client"
                                />
                                {(touched.client && errors.client) && <span className="error">{errors.client}</span>}
                            </div>
                            <div className="form-select w-3/12 mb-4 relative mt-6">
                                <label>Student</label>
                                {data_st &&
                                <CustomSelect
                                    name="student"
                                   // isDisabled={true}
                                    className='input'
                                    onChange={handleStudentChange}
                                    onBlur={handleBlur}
                                    value={parseInt(data_st.student)}
                                    options={studentOptions}
                                    placeholder="Search Student"
                                />   }
                                {(touched.student && errors.student) && <span className="error">{errors.student}</span>}
                            </div>
                                
                            <div className="form-select w-3/12 mb-4 relative mt-6">
                                <label>Status</label>
                                {/* {data_client.client !== null && userId === null ?( */}
                                     <CustomSelect
                                     name="status"
                                     className='input'
                                     options={statusOption}
                                     onChange={e => {
                                         setStatus({});
                                         setFieldValue('status',e.value);
                                     }}
                                     value={data_status.student_status?data_status.student_status:CancelStatus}
                                 />
                                {/* ):(
                                <CustomSelect
                                    name="status"
                                    className='input'
                                    options={statusOption}
                                    onChange={e => {
                                        setStatus({});
                                        setFieldValue('status',e.value);
                                    }}
                                    value={CancelStatus?CancelStatus:''}
                                 /> 
                                )} */}
                               
                                {(touched.status && errors.status) && <span className="error">{errors.status}</span>}
                            </div>
                            <div className="form-select w-3/12 mb-4 relative mt-6">
                                <label>Paid Status</label>
                                <CustomSelect
                                    name="paid"
                                    isDisabled={data.paid === 'Credit Card' || data.paid === 'Gift Card' ? true : false}
                                    className='input'
                                    onChange={handlePaymentStatus}
                                    value={PaidStatus?PaidStatus:selectedValue}
                                    options={paidstatusOption}
                                />
                                {(touched.paid && errors.paid) && <span className="error">{errors.paid}</span>}
                            </div>
                        </div>

                    </AccordionBody>
                </Accordion>

                <Accordion open={openAcc2} icon={<Icon id={2} open={openAcc2} />} animate={customAnimation} className="accordion-main">
                    <AccordionHeader onClick={handleOpenAcc2} className="accordion-heading">
                    Appointment Details
                    </AccordionHeader>
                    <AccordionBody className="accordion-body">
                        
                        <div className="flex gap-6 appointment-details-row mb-3">

                            <div className="form-select w-6/12 message-type mb-4 relative mt-6">
                                <label>Massage Type</label>
                                <CustomSelect
                                    name="massage_type"
                                    className='input'
                                    isDisabled={true}
                                    onChange={event => {
                                        setMassage({});
                                        setFieldValue('massage_type',event.value);
                                    }}
                                    value={parseInt(data_massage.massage_type)}
                                    options={massageOption}
                                />
                                {(touched.massage_type && errors.massage_type) && <span className="error">{errors.massage_type}</span>}
                            </div>

                            <div className="form-input w-3/12 date-picker mb-4 relative mt-6">
                                <label>Date</label>
                                <div class="date-custom-outer">
                                    <Datepicker 
                                        className="w-full"
                                        displayFormat={"DD/MM/YYYY"}
                                        useRange={false} 
                                        asSingle={true}
                                        onChange={handleChange} 
                                        show={show} 
                                        setShow={handleClose}  
                                        selected={selectedDate}
                                        onFocus={() => setShow(true)} 
                                        disabled={true}
                                    />
                                    <div className="date-test">
                                        <input
                                            type="text"
                                            className="date-input-innner"
                                            placeholder="Select Date"
                                            value={selectedDate}
                                            onFocus={() => setShow(true)}
                                            readOnly
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="form-select w-3/12 mb-4 relative mt-6 time-slot">
                                <label>Time Slot</label>
                                <CustomSelect
                                    name="time"
                                    isDisabled={true}
                                    className='input'
                                    onChange={e => {
                                        setFieldValue('time',e.value);
                                        setTime({});
                                    }}
                                    value={data_time.time}
                                    options={optionstime}
                                />
                                {(touched.timeslot && errors.timeslot) && <span className="error">{errors.timeslot}</span>}
                            </div>
                        </div>

                        <div className="flex gap-6">
                            <div className="form-input w-6/12 mb-4 relative mt-6">
                                <label>Appointment Notes</label>
                                <Input 
                                    name="notes"
                                    className='input'
                                    variant="static"
                                    placeholder="Enter appointment notes here if applicable."
                                    onChange={e => {
                                        setNotes({});
                                        setFieldValue('notes',e.target.value);
                                    }}
                                    value={data_notes.notes}                            
                                />
                            </div>

                            <div className="form-select w-6/12 mb-4 relative mt-6">
                                <label>Client Notes</label>
                                { data.client != null ?(
                                <CustomSelect
                                    name="specialneeds"
                                    className='input'
                                    value={data_clientnotes.specialneed}
                                    onChange={handleclientnoteChange}
                                    options={specialneeds}
                                />
                              ):(
                                <CustomSelect
                                    name="specialneeds"
                                    className='input'
                                    value={clientnotes?clientnotes:''}
                                    onChange={handleclientnoteChange}
                                    options={specialneeds}
                                />
                                )}  
                              {(touched.specialneeds && errors.specialneeds) && <span className="error">{errors.specialneeds}</span>}
                            </div>
                        </div>

                    </AccordionBody>
                </Accordion>

                <Accordion open={openAcc3} icon={<Icon id={3} open={openAcc3} />} animate={customAnimation} className="accordion-main payment-accordion">
                    <AccordionHeader onClick={handleOpenAcc3} className="accordion-heading">
                      Payment
                    </AccordionHeader>
                    <AccordionBody className="accordion-body">

                        <div className="form-select w-6/12 mb-3 relative mt-2">
                            <label>Payment Status</label>
                            <CustomSelect
                                name="paid"
                                className='input'
                                isDisabled={data.paid === 'Credit Card' || data.paid === 'Gift Card' ? true : false}
                                onChange={e => {
                                    setPaidStatus({});
                                    setFieldValue('paid',e.value);
                                    setSelectedValue(e.value);
                                    setPaymentLabel(e.label);
                                }}
                                value={PaidStatus?PaidStatus:selectedValue}
                                options={paidstatusOption}
                            />
                            {(touched.paid && errors.paid) && <span className="error">{errors.paid}</span>}
                        </div>

                        <div className="payment-grid">
                            {data.paid === 'Credit Card' && data.client != null?(<>
                                    <h4>Preferred payment schedule</h4>
                                    <div className="mb-3">
                                        <div className="w-6/12">
                                            <div className="payment-details border-radius-box">
                                                <h4>Pay in full now <span>${parseFloat(TotalServicePaid).toFixed(2)}</span></h4>
                                                <p>Date paid <span>({Moment(AppointmentOrders.created_at).format('DD/MM/YYYY')})</span></p>
                                             </div>
                                        </div>
                                    </div>
                            </>) : (<>
                                { AppointmentOrders && data.client != null && data.paid === 'Gift Card' && <>
                                    <h4>Preferred payment schedule</h4>
                                    <div className="mb-3">
                                        <div className="w-6/12">
                                            <div className="payment-details border-radius-box">
                                            
                                            <>
                                                <h4>Gift Card <span>${parseFloat(TotalServicePaid).toFixed(2)}</span></h4>
                                                <p>Date paid <span>({Moment(AppointmentOrders.created_at).format('DD/MM/YYYY')})</span></p>
                                                <p>Brief explanation about the deposit payment, when the balance is due etc.</p>
                                            </> 
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </>)}
                            
                            {paidValue === 'Credit Card' ?(
                                ""
                            ) : (<>
                                { data.client !== null && data.paid !== 'Gift Card' && data.paid !== 'Credit Card' && <>
                                    <h4>Preferred payment schedule</h4>
                                    <div className="mb-3">
                                        <div className="w-6/12">
                                            <div className="payment-details border-radius-box">
                                             <h4>{AppointmentOrders.payment_method} <span>${parseFloat(AppointmentOrders.price).toFixed(2)}</span></h4>
                                                <p>Date paid <span>({Moment(AppointmentOrders.created_at).format('DD/MM/YYYY')})</span></p>
                                                <p>Brief explanation about the deposit payment, when the balance is due etc.</p>
                                             </div>
                                        </div>
                                    </div>
                            </>}
                            </>)}

                            <div className={data.paid === 'Credit Card' ? "hidden" : ""}>

                                {paidValue === 'Credit Card' ? (<>
                                    <div className="w-6/12 mb-5">
                                        <div className="form-input no-label relative">
                                            {results.TotalServicePrice > 0 && <>
                                                <label>Card Information:</label>
                                                <CardElement />
                                            </> }
                                        </div>
                                    </div>

                                    <div className="flex checkbox-row w-6/12">
                                            {!isGiftCardUsed && results.TotalServicePrice > 0 ?(
                                                <div className="flex flex-col w-full">
                                                    <label for="">Gift Card</label>
                                                    <div className="gift-card">
                                                        <Input
                                                        type="text"
                                                        variant="static"
                                                        fullwidth="true"
                                                        placeholder="Add Gift Card"
                                                        id="Gift"
                                                        {...getFieldProps('gift_card')}
                                                        className="border-black placeholder-shown:border-black"
                                                        />
                                                    
                                                        {Voucher && Voucher.status === 'success' ? (
                                                        <span className="alert alert-success">{Voucher.message}</span>  
                                                        ):(
                                                        <Button onClick={handleSearch} className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block 
                                                            text-white capitalize text-sm px-8 py-3">Apply</Button> 
                                                        )}
                                                        {Voucher && Voucher.status === 'error' ? (
                                                        <div className="te">
                                                            <span className="alert alert-danger">{Voucher.message}</span>  
                                                        </div>
                                                        ):('')}
                                                    </div>
                                                </div>
                                              ):isGiftCardUsed ?(
                                                <div className="flex flex-col w-full">
                                                <label for="">Gift Card</label>
                                                <div className="gift-card">
                                                    <Input
                                                    type="text"
                                                    variant="static"
                                                    fullwidth="true"
                                                    placeholder="Add Gift Card"
                                                    id="Gift"
                                                    {...getFieldProps('gift_card')}
                                                    className="border-black placeholder-shown:border-black"
                                                    />
                                                
                                                    {Voucher && Voucher.status === 'success' ? (
                                                    <span className="alert alert-success">{Voucher.message}</span>  
                                                    ):(
                                                    <Button onClick={handleSearch} className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block 
                                                        text-white capitalize text-sm px-8 py-3">Apply</Button> 
                                                    )}
                                                    {Voucher && Voucher.status === 'error' ? (
                                                    <div className="te">
                                                        <span className="alert alert-danger">{Voucher.message}</span>  
                                                    </div>
                                                    ):('')}
                                                </div>
                                            </div>
                                              ):('')}
                                    </div>
                                </>) : null}
                            </div>
                        </div>
                    </AccordionBody>
                </Accordion>
                </Fragment>
                <hr/>
                    {data_client.client !== null &&
                        <div className="complete-appointment">
                            
                            <h2>Payment Summary</h2>

                            <div className="overflow-x-auto relative">
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <tbody>

                                        <tr className="bg-white dark:bg-gray-800">
                                           
                                            <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Price <br/>
                                                {paymentlabel &&
                                                    <span>{paymentlabel}</span>
                                                }
                                            </th>

                                            <td className="py-4 text-right">
                                                {data.promo_price ? (
                                                 <>
                                                   <span className='text-red-500 mr-2'>${parseFloat(data.promo_price).toFixed(2)}</span>
                                                   <span className="line-through font-normal opacity-40">${parseFloat(data.service_price).toFixed(2)}</span>
                                                 </>
                                                ):(
                                                  <span className="regular price">${parseFloat(data.service_price).toFixed(2)}</span>  
                                                )}
                                            </td>

                                        </tr>
                                        
                                        
                                        <tr className="bg-white dark:bg-gray-800">

                                            <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Additional Charges <br/><span>Cancellation Fee from previous appointment </span>
                                            </th>

                                            {(AppointmentOrders && AppointmentOrders.status !=='Pending') && data.client != null ?(
                                                <td className="py-4 text-right">
                                                     ${parseFloat(AppointmentOrders.Cancellation_Fees) > 0 ? parseFloat(AppointmentOrders.Cancellation_Fees).toFixed(2) : '0.00'}
                                                </td>
                                            ): cancelledFees ?(
                                                <td className="py-4 text-right">
                                                   ${parseFloat(cancelledFees).toFixed(2)}
                                                </td>
                                            ):(
                                                <td className="py-4 text-right">
                                                    $0.00
                                                </td>
                                            )}
                                        </tr>
                                        
                                            {(AppointmentOrders && AppointmentOrders.status !=='Pending') && data.client !=null ?(
                                                <tr className="bg-white dark:bg-gray-800">
                                                    {parseFloat(AppointmentOrders.gift_price) > 0 && <>
                                                    <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        Applied Gift Card Amount
                                                        <br/><span>{data.voucher} (Remaining ${parseFloat(data.voucher_balance).toFixed(2)})</span>
                                                    </th>
                                                        <td className="py-4 text-right">
                                                           ${parseFloat(AppointmentOrders.gift_price) > 0 ? parseFloat(AppointmentOrders.gift_price).toFixed(2):'0.00'}
                                                        </td>
                                                 </> }
                                                 </tr>
                                            ):(
                                            <tr className="bg-white dark:bg-gray-800">
                                                <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    Applied Gift Card Amount <br/>
                                                    {Voucher && 
                                                     <span>{Voucher.code} (Remaining ${results.RemainingGiftamount?parseFloat(results.RemainingGiftamount).toFixed(2):'0.00'})</span>
                                                   }
                                                </th>
                                                <td className="py-4 text-right">
                                                     - ${results.UsedGiftamount?parseFloat(results.UsedGiftamount).toFixed(2):"0.00"}
                                                </td>
                                             </tr>  
                                            )}


                                        {(AppointmentOrders && AppointmentOrders.status !=='Pending' ) && data.client !=null ?(
                                            <tr className="bg-white dark:bg-gray-800">
                                                <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    Applied Credit
                                                </th>
                                                    <td className="py-4 text-right">
                                                        - ${ parseFloat(AppointmentOrders.wallet_credit) > 0 ? parseFloat(AppointmentOrders.wallet_credit).toFixed(2) : 0.00 }
                                                    </td>
                                              </tr>
                                         ):( 
                                            <tr className="bg-white dark:bg-gray-800">
                                                <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    Applied Credit
                                                </th>
                                               
                                                <td className="py-4 text-right">
                                                    {parseFloat(results.AppliedCredit) > 0 ?(<>
                                                        - ${parseFloat(results.AppliedCredit).toFixed(2)}
                                                    </>):(
                                                      <>  $({parseFloat(results.AppliedCredit).toFixed(2)})</>  
                                                    )}
                                                </td>
                                            </tr>
                                        )}

                                        <tr className="bg-white dark:bg-gray-800">
                                            <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Remaining Balance Payment Method
                                            </th>
                                            {AppointmentOrders &&
                                                <td className="py-4 text-right">
                                                    {AppointmentOrders.payment_method}
                                                </td>
                                            }
                                        </tr>

                                        <tr>
                                            <td colspan="2">
                                             <hr/>
                                           </td>
                                        </tr>

                                          <tr className="bg-white dark:bg-gray-800">
                                            <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Total Amount:
                                            </th>
                                            {(AppointmentOrders && AppointmentOrders.status !=='Pending') && data.client !=null ?(
                                                <td className="py-4 text-right">
                                                    ${ parseFloat(TotalServicePaid).toFixed(2) }
                                                </td>
                                                ):(
                                                <td className="py-4 text-right">
                                                 ${parseFloat(results.TotalAmountService).toFixed(2)}
                                            </td>
                                            )}
                                        </tr>
                                     
                                        <tr className="bg-white dark:bg-gray-800">
                                            {(AppointmentOrders && AppointmentOrders.status !=='Pending') && data.client !=null ?(     <>
                                                <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    Total Amount Paid:
                                                </th>                              
                                                <td className="py-4 text-right">
                                                    ${parseFloat(AppointmentOrders.stripe_price)?parseFloat(AppointmentOrders.stripe_price).toFixed(2):'0.00'}
                                                </td>
                                            </>  ):( <>
                                             <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                               Total Amount Due Now:
                                            </th>
                                        
                                            <td className="py-4 text-right">
                                                ${results.TotalServicePrice > 0 ? parseFloat(results.TotalServicePrice).toFixed(2) :'0.00' }
                                            </td>
                                            </> )}
                                        </tr>
                                     </tbody>
                                </table>
                            </div>
                        </div>
                        }

                         <div className="flex justify-start z-10 relative">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: data.student_booked_date }}></div>
                                <div dangerouslySetInnerHTML={{ __html: data.client_booked_date }}></div>
                            </div>
                         </div>
                         
                        <div className="bottom-button-group flex flex-wrap justify-end z-10 relative">
                         {data.status === 'Available' &&
                           <Button onClick={openDialogDelete} className="bg-red-500 hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3">Delete</Button>
                          }
                            <div className="col">
                                <Button onClick={handleComplete} disabled={!data_client.client} className="outline-btn-black" variant="outlined">Mark As Completed</Button>
                            </div>
                            <div className="col">
                                <Button onClick={openDialog} disabled={!data_client.client} className="outline-btn-black" variant="outlined">Remove Client From Appointment</Button>
                            </div>
                            <div className="col">
                                <Button className="btn-black" type="submit">Book Appointment</Button>
                               {/* { data.status === 'Booked' && paidValue !== 'Awaiting Payment' ?
                                     <Button className="btn-black" type="submit">Book Appointment</Button>
                                     : data.status === 'Booked'  ?
                                      <Button className="btn-black" onClick={() => handleUpdate(values)}>Update Appointment</Button>
                                    : <Button className="btn-black" type="submit">Book Appointment</Button>
                                } */}
                            </div>
                        </div>

                    </form>
                </div>


                {user.user_type_primary === 'admin' &&

                    <Dialog open={dialogopen} size="lg" handler={dialogHandleOpen} className="px-6 py-8">

                        <Typography variant="h2" className="text-2xl text-center text-blue-900 font-bold mb-5">Select your cancellation type:</Typography>

                        <div className="flex flex-col">
                        {PaidStatus === 'Credit Card' || PaidStatus === 'Gift Card' || PaidStatus === 'Applied Credit' || PaidStatus === 'Cash' || PaidStatus === 'EFT' ? (
                            <Radio
                                id="feeonly"
                                name="type"
                                label="Remove client from appointment and apply entire fee to client’s credit"
                                value={"Remove client from appointment and apply entire fee to client credit"}
                                onChange={handleCancelOption}
                                icon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3 w-3"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                }
                              />
                        ) : (
                            <Radio
                                id="givecredit"
                                name="type"
                                label="Remove client from appointment ONLY"
                                value={"Remove client from appointment ONLY"}
                                onChange={handleCancelOption}
                                icon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3 w-3"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                }
                            />
                        )}

                        {PaidStatus === 'Credit Card' || PaidStatus === 'Gift Card' || PaidStatus === 'Applied Credit' ? (
                          <Radio
                            id="no"
                            name="type"
                            label="SAME DAY CANCELATION - Remove client from appointment and apply credit (minus cancelation fee of $15)"
                            value={"SAME DAY CANCELATION with paid online client"}
                            onChange={handleCancelOption}
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            }
                            // defaultChecked
                          />
                        ) : (
                            <Radio
                                id="freeno"
                                name="type"
                                label="SAME DAY CANCELATION - Remove client from appointment and apply a cancelation fee of $15"
                                value={"SAME DAY CANCELATION without paid online client"}
                                onChange={handleCancelOption}
                                icon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-3 w-3"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                }
                              />
                        )}
                        </div>

                        <DialogFooter className="block text-center flex justify-center p-0 gap-6 mt-4">
                            <Button onClick={handleCancellation} className="btn-black" type="submit">Remove Client From Appointment</Button>
                            <Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8" onClick={closeDialog}>Close</Button>
                        </DialogFooter>
                    </Dialog>
                }

                <Dialog open={dialogopenstudent} size="sm" className="px-6 py-8">
                    <Typography variant="h2" className="text-2xl text-center text-blue-900 font-bold mb-5">Student matched with current appointment</Typography>
                    <DialogFooter className="block text-center flex justify-center p-0 gap-6 mt-4">
                        <Button className="btn-black" type="submit" onClick={() => handleUpdate(values)}>Update Appointment</Button>
                        <Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8" onClick={closeDialogStudent}>Close</Button>
                    </DialogFooter>
                </Dialog>

                <Dialog open={dialogopenstudenterror} size="sm" className="px-6 py-8">
                    <Typography variant="h2" className="text-2xl text-center text-blue-900 font-bold mb-5">Student not matched please assign new student that have same availability</Typography>
                    <DialogFooter className="block text-center flex justify-center p-0 gap-6 mt-4">
                        <Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8" onClick={closeDialogStudentError}>Close</Button>
                    </DialogFooter>
                </Dialog>


            <Dialog open={dialogopendelete} size="md" handler={dialogHandleDeleteOpen} className="px-6 py-8">
                <Typography variant="h2" className="text-2xl text-center text-blue-900 font-bold mb-5">Are you sure you would like to delete this Appointment?</Typography>
                <DialogFooter className="block text-center flex justify-center p-0 gap-6 mt-4">
                    <Button onClick={(event) => { handleDelete(event,data.id)}} className="bg-red-500 hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Confirm</Button>
                    <Button className="bg-blue-900 hover:bg-black active:bg-black focus:bg-black rounded-md shadow-none text-white capitalize text-sm px-8" onClick={closeDialogDelete}>Close</Button>
                </DialogFooter>
            </Dialog>

        </>
    )
}
export default EditAppointmentForm;