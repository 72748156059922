import { Fragment, useState, useEffect } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { useFormik } from 'formik';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import * as Yup from 'yup';
import AuthUser from '../../../helpers/AuthUser';
import toast from 'react-hot-toast';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Input,
  Button
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import {getAdminUrl} from "../../../helpers/Utils";
import TopNav from '../../../Components/TopNav/TopNav';
import CustomSelect from '../../../helpers/CustomSelect';
import {fncalculationswithgiftcard} from '../../../helpers/checkoutCalculations';
import {statusOption, optionstime, paidstatusOption, sourceOption, optionscovid} from '../../../helpers/appointmentOptions';
function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const topNav = [

    {
        name: "View All",
        url: getAdminUrl('appointments')
    },
    {
        name: "Remedial/Deep Tissue",
        url: getAdminUrl('appointments/remedial')
    },
    {
        name: "Myotherapy/Advanced Treatment",
        url: getAdminUrl('appointments/myotherapy')
    },
    {
        name: "Relaxation/Basic Treatment",
        url: getAdminUrl('appointments/relaxation')
    },
    {
        name: "Book Appointment",
        url: getAdminUrl('add-appointment'),
        button: [
            {
                name: "Book Appointment"
            }
        ]
    }
]

function AppointmentForm() {

    const navigate = useNavigate();
    const {http, getStudents,getSpecialNeedOptions,getClients} = AuthUser();
    const [paymentError, setPaymentError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error,setError] = useState(false);
    const [done,setDone] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const [open, setOpen] = useState(3);
    
    const [openAcc1, setOpenAcc1] = useState(true);
    const [openAcc2, setOpenAcc2] = useState(true);

    const [users, setUsers] = useState([]);
    const [clients, setClient] = useState([]);

    const [service, setService] = useState([]);    
    const [specialneed, SpecialNeeds] = useState([]);
    const [Voucher, SetVoucher] = useState(null);
    const [message, setMessage] = useState(null);
    const [balance, SetCurrentBalance] = useState(null);
    const [SelectedService, setAmount] = useState(null);
    const [pendingBalance, setPendingBalance] = useState(0);
    const [cancelledFees, setcancelledFees] = useState(0);
    const [userId, setselectedClient] = useState(null);
    const [paymentlabel, setPaymentLabel] = useState(null);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };
    const handleOpenAcc1 = () => setOpenAcc1((cur) => !cur);
    const handleOpenAcc2 = () => setOpenAcc2((cur) => !cur);
    
    const customAnimation = {
        mount: { opacity: 1 },
        unmount: { opacity: 0 },
    };

    const handleValueChange = (SelectedDate) => {
        setFieldValue("appointment_date", SelectedDate); 
    } 
    useEffect(() => {
      UsersGet()
      ServiceGet()
      GetNeeds()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const UsersGet = () => {
   getStudents({per_page:500}).then((resp)=>{
      setUsers(resp.data.data);
   });
   getClients({per_page:500}).then((resp)=>{
      setClient(resp.data.data);
   });
  }

  const GetNeeds = () => {  
      getSpecialNeedOptions().then((resp)=>{
         SpecialNeeds(resp.data.data);
      });
   }

  const ServiceGet = () => {
      http.get('auth/services').then((resp)=>{
          setService(resp.data.data);
      });  
  }

    const handleSearch = async (event) => {
        SetVoucher(null);
        const code1 = getFieldProps('gift_card').value;
        if(code1){
          let parms = {
            code: code1,
          };
        await http.post('/redeemVoucher',parms).then((resp)=>{
            SetVoucher(resp.data);
            SetCurrentBalance(resp.data.balance);
        }).catch(err => {
           SetVoucher('');
           setMessage(err.response);
        });
        }
    }

    const specialneeds = specialneed.map(option => ({
        "value" : option.id,
        "label" : option.title
    }))
    const studentOptions = users.map(d => ({
        "value" : d.id,
        "label" : d.first_name + ' ' + d.last_name  + ' (' + d.email + ') '
    }))

    const clientOptions = clients.map(d => ({
        "value" : d.id,
        "label" : d.first_name + ' ' + d.last_name  + ' (' + d.email + ') '
    }))

    const massageOption = service.map(option => ({
        "value" : option.id, 
        "label" : option.title,
        "price":  option.sale_price?option.sale_price:option.price
    }))

    const fetchPendingBalance = async (userID) => {
        try {
          const response = await http.get(`/user/${userID}/pending-balance`);
           const  pendingBalance  = response.data;
           setPendingBalance(pendingBalance.balance);
           setcancelledFees(pendingBalance.cancelledFees);
        } catch (error) {
          // console.log(error);
        }
    };
  
    const fetchselectedService = async (serviceId) => {
        try {
            const response = await http.get(`auth/services/${serviceId}`);
             const  data         = response.data.data;
             const serviceprice  = data.promo_price?data.promo_price:data.price;
             setAmount(serviceprice); 
          } catch (error) {
            // console.log(error);
          }
    }

    const fetchSelectedStudentDetails = async (userID) => {
        try {
          const response = await http.get(`auth/user/${userID}`);
          const selectedservice = response.data.data.massage_type;
           setFieldValue("massage_type", response.data.data.massage_type); 
           fetchselectedService(selectedservice);
        } catch (error) {
          // console.log(error);
        }
    };

  

    const fetchSelectedClientDetails = async (userID) => {
        try {
          const response = await http.get(`auth/user/${userID}`);
           setFieldValue('specialneeds',response.data.data.special_id)
        } catch (error) {
          // console.log(error);
        }
    };

    const handleClientChange =(selectedClient) =>{
        fetchSelectedClientDetails(selectedClient.value);
        setFieldValue("client", selectedClient.value); 
        setFieldValue("status", 'Booked'); 
        setselectedClient(selectedClient.value);
        fetchPendingBalance(selectedClient.value);
    }

    const handleStudentChange =(selectedStudent) =>{
        fetchSelectedStudentDetails(selectedStudent.value);
        setFieldValue("student", selectedStudent.value);        
    }

    const handleSelectedService = (SelectedService) => {
       setFieldValue("massage_type", SelectedService.value); 
       setAmount(SelectedService.price); 
    } 

    const _fncalculationsresults =  () => {
        let ServiceAmount       = parseInt(SelectedService?SelectedService:0.00);
    
        let GiftCardBalance      = parseInt(balance?balance:'0.00');
        let AppliedCredit        = parseInt(pendingBalance?pendingBalance:'0.00');
        let Cancellation_Fee     = parseInt(cancelledFees?cancelledFees:'0.00');
        let RemainingGiftamount  = '0.00';
        let RemainingApplCredit  = '0.00';
        let UsedGiftamount       = '0.00';
        let TotalServicePrice    = '0.00';
        let TotalAmountService   = '0.00';
        let SelectedServicePrice = ServiceAmount;
        return fncalculationswithgiftcard(SelectedServicePrice,Cancellation_Fee, AppliedCredit, GiftCardBalance,RemainingGiftamount,TotalServicePrice,UsedGiftamount,RemainingApplCredit,TotalAmountService);
    };

    const results = _fncalculationsresults();

    const makePaymentRequest = async (allformData)=>{
        await http.post('appointment/create',JSON.stringify(allformData, null, 2)).then((response)=>{
            setError(false)
            setDone(true)
            setIsLoading(false)
            if(response.data.status === true){
                toast.success(response.data.message)
            }else{
                toast.error('Something is wrong! Please try again') 
            }
            navigate('/admin/appointments');
        }).catch(error => {
           setError(true)
           setPaymentError("Something is wrong!");
           setMessage(error?.response?.data?.message);
        });
    }
    
    const { handleSubmit, handleBlur, errors, touched, getFieldProps, setFieldValue } = useFormik({
        initialValues: {
            client: '',
            student: '',
            paid: '',
            times:'',
        },
        validationSchema: Yup.object({
            client: Yup.string().required('Option is required'),
            student: Yup.string().required('Student is required'),
            paid: Yup.string().nullable().required('Paid status is required'),
            times: Yup.string().nullable().required('Time is required')
        }),
        onSubmit: async (values) => {
            
            values = {...values, appointment_date:values.appointment_date.startDate}
            let cartTotal         = parseFloat(results.TotalServicePrice).toFixed(2);
            let TotalServicePrice = parseFloat(results.TotalAmountService).toFixed(2);
            let voucheramount     = parseFloat(results.UsedGiftamount).toFixed(2);
            let applied_credit    = parseFloat(results.AppliedCredit).toFixed(2);
            let Cancellation_Fees = parseFloat(results.Cancellation_Fee).toFixed(2);
            // setIsLoading(true);
            if(values.paid === 'Cash' || values.paid === "EFT" || values.paid === "Staff" || values.paid === "Free" ){
                const allFormData = {
                    ...values,
                    amount:cartTotal,
                    totalserviceprice:TotalServicePrice,
                    giftcard:voucheramount,
                    applied_credit:applied_credit,
                    Cancellation_Fees:Cancellation_Fees,
                    paymentMethod:'',
                    stripeToken:'',
                }
               await makePaymentRequest(allFormData);
               return;
            } else if (parseFloat(cartTotal) <= 0 || parseFloat(cartTotal) === 0.00 ) {
                const allFormData = {
                    ...values,
                    amount:cartTotal,
                    totalserviceprice:TotalServicePrice,
                    giftcard:voucheramount,
                    applied_credit:applied_credit,
                    Cancellation_Fees:Cancellation_Fees,
                    paymentMethod:'',
                    stripeToken:'',
                 }
                await makePaymentRequest(allFormData);
                return;
            }
        
        if (!stripe || (!elements && cartTotal > 0)) {
            console.error("Stripe.js has not loaded yet.");
            setIsLoading(false);
            return;
        }
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            console.error("CardElement is not mounted.");
            setIsLoading(false);
            return;
        }
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
            const stripeToken = await stripe.createToken(cardElement);
            if (error) {
                // setPaymentError(error.message);
                setIsLoading(false);
                return;
            }
            const allFormData = {
                ...values,
                stripeToken:stripeToken.token.id,
                amount:cartTotal,
                totalserviceprice:TotalServicePrice,
                giftcard:voucheramount,
                applied_credit:applied_credit,
                Cancellation_Fees:Cancellation_Fees,
                payment_method:paymentMethod.id,
            }
            await makePaymentRequest(allFormData);
         } catch (err) {
            console.error("Error during payment processing:", err);
            setPaymentError("An error occurred during payment processing.");
            setIsLoading(false);
        }
      }
    });

    const paidFieldValue = getFieldProps('paid').value;
    if(isLoading)  return  <div className="text-center"> <h1 className="text-success mt-5">Wait, Processing Your Payment</h1> </div>
    return (
        <>

            <TopNav data={topNav} />
            <div className="white-box">
                <form className="custom-form" onSubmit={handleSubmit}>
                    <Fragment>
                      <Accordion open={openAcc1} icon={<Icon id={1} open={openAcc1} />} animate={customAnimation} className="accordion-main">
                        <AccordionHeader onClick={handleOpenAcc1} className="accordion-heading">
                          New Appointment
                        </AccordionHeader>
                        <AccordionBody className="accordion-body">
                            
                            <div className="row mb-3">
                                <div className="col-6 px-4">
                                    <div className="form-select w-full mb-4 relative mt-6">
                                        <label>Client</label>
                                        <CustomSelect
                                            className='input'
                                            onChange={handleClientChange}
                                            value={getFieldProps('client').value}
                                            options={clientOptions}
                                            placeholder="Search Client"
                                            onBlur={handleBlur}
                                        />
                                     {(touched.client && errors.client) && <span className="error">{errors.client}</span>}
                                    </div>
                                </div>
                                <div className="col-6 px-4">
                                    <div className="form-select w-full mb-4 relative mt-6">
                                        <label>Student</label>
                                        <CustomSelect
                                            className='input'
                                            onChange={handleStudentChange}
                                            value={getFieldProps('student').value}
                                            options={studentOptions}
                                            placeholder="Search student"
                                            onBlur={handleBlur}
                                        />
                                         {(touched.student && errors.student) && <span className="error">{errors.student}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3 px-4">
                                    <div className="form-select w-full mb-4 relative mt-6">
                                        <label>Source</label>
                                        <CustomSelect
                                            className='input'
                                            onChange={value=> setFieldValue('source',value.value)}
                                            value={getFieldProps('source').value}
                                            options={sourceOption}
                                        />
                                    </div>
                                </div>
                                <div className="col-3 px-4">
                                    <div className="form-select w-full mb-4 relative mt-6">
                                        <label>Status</label>
                                        <CustomSelect
                                            className='input'
                                            onChange={value=> setFieldValue('status',value.value)}
                                            value={getFieldProps('status').value}
                                            options={statusOption}
                                        />
                                        {(touched.status && errors.status) && <span className="error">{errors.status}</span>}
                                    </div>
                                </div>
                                <div className="col-3 px-4">
                                    <div className="form-select w-full mb-4 relative mt-6">
                                        <label>Paid Status</label>
                                        <CustomSelect
                                            className='input'
                                            onChange={ value => {
                                                setFieldValue('paid',value.value)
                                                setPaymentLabel(value.label);
                                            }}
                                            value={getFieldProps('paid').value}
                                            options={paidstatusOption}
                                        />
                                     {(touched.paid && errors.paid) && <span className="error">{errors.paid}</span>}
                                    </div>
                                </div>
                            </div>

                        </AccordionBody>
                      </Accordion>

                      <Accordion open={openAcc2} icon={<Icon id={2} open={openAcc2} />} animate={customAnimation} className="accordion-main">
                        <AccordionHeader onClick={handleOpenAcc2} className="accordion-heading">
                          Appointment Details
                        </AccordionHeader>
                        <AccordionBody className="accordion-body">
                            
                            <div className="row appointment-details-row mb-3">
                                <div className="col-4 px-4 message-type">
                                    <div className="form-select w-full mb-4 relative mt-6">
                                        <label>Massage Type</label>
                                        <CustomSelect
                                            className='input'
                                            name="massage_type"
                                            isDisabled={true}
                                            onChange={handleSelectedService}
                                            value={getFieldProps('massage_type').value}
                                            options={massageOption}
                                        />
                                        {(touched.massage_type && errors.massage_type) && <span className="error">{errors.massage_type}</span>}
                                    </div>
                                </div>
                                <div className="col-4 px-4 date-picker">
                                    <div className="form-select days-input relative">
                                        <label htmlFor="rol">Day(s)</label>
                                        <Datepicker 
                                            className="w-full"
                                            displayFormat={"DD/MM/YYYY"}
                                            placeholder={"Date"} 
                                            useRange={false} 
                                            asSingle={true} 
                                            {...getFieldProps('appointment_date')}
                                            selected={(getFieldProps('appointment_date').value && new Date(getFieldProps('appointment_date').value)) || null}
                                            onChange={handleValueChange}
                                        />
                                    {(touched.appointment_date && errors.appointment_date) && <span className="error">{errors.appointment_date}</span>}                              
                                    </div>
                                </div>
                                <div className="col-4 px-4 time-slot">
                                    <div className="form-select w-full mb-4 relative mt-6">
                                        <label>Time Slot</label>
                                        <CustomSelect
                                            className='input'
                                            onChange={value=> setFieldValue('times',value.value)}
                                            value={getFieldProps('times').value}
                                            options={optionstime}
                                        />
                                        {(touched.times && errors.times) && <span className="error">{errors.times}</span>}
                                    </div>
                                </div>
                                <div className="col-4 px-4 covid-vacci">
                                    <div className="form-select w-full mb-4 relative mt-6">
                                        <label>COVID-19 Vaccination Status</label>
                                        <CustomSelect
                                            className='input'
                                            onChange={value=> setFieldValue('is_vaccinated',value.value)}
                                            value={getFieldProps('is_vaccinated').value}
                                            options={optionscovid}
                                        />
                                        {(touched.is_vaccinated && errors.is_vaccinated) && <span className="error">{errors.is_vaccinated}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6 px-4">
                                    <div className="form-input w-full mb-4 relative mt-6">
                                        <label>Appointment Notes</label>
                                         <Input 
                                            name="notes"
                                            variant="static"
                                            className='input'
                                            {...getFieldProps('notes')}
                                            placeholder="Enter appointment notes here if applicable."
                                          />
                                        {(touched.notes && errors.notes) && <span className="error">{errors.notes}</span>}
                                    </div>
                                </div>
                                <div className="col-6 px-4">
                                    <div className="form-select w-full mb-4 relative mt-6">
                                        <label>Special Needs</label>
                                        <CustomSelect
                                            className='input'
                                            onChange={value=> setFieldValue('specialneeds',value.value)}
                                            value={getFieldProps('specialneeds').value}
                                            options={specialneeds}
                                        />
                                        {(touched.specialneeds && errors.specialneeds) && <span className="error">{errors.specialneeds}</span>}
                                    </div>
                                </div>
                            </div>
                        </AccordionBody>
                      </Accordion>

                      <Accordion open={open === 3} icon={<Icon id={3} open={open} />} animate={customAnimation} className="accordion-main payment-accordion">
                        <AccordionHeader onClick={() => handleOpen(3)} className="accordion-heading">
                          Payment
                        </AccordionHeader>
                        <AccordionBody className="accordion-body">


                           <div className="form-select w-6/12 mb-3 relative mt-2">
                                <label>Payment Status</label>
                                <CustomSelect
                                  className='input'
                                  onChange={ value => {
                                    setFieldValue('paid',value.value)
                                    setPaymentLabel(value.label);
                                  }}
                                  value={getFieldProps('paid').value}
                                  options={paidstatusOption}
                                />
                                {(touched.paid && errors.paid) && <span className="error">{errors.paid}</span>}
                            </div>

                            <div className="payment-grid">
                                
                                {paidFieldValue === 'Credit Card' &&<>
                                    <div className="w-6/12 mb-5">
                                        <div className="form-input no-label relative">
                                            <label>Card Information:</label>
                                            <CardElement />
                                        </div>
                                    </div>

                                    <div className="flex checkbox-row w-6/12">
                                        <div className="flex flex-col w-full">
                                            <label for="">Gift Card</label>
                                            <div className="gift-card">
                                                <Input
                                                type="text"
                                                variant="static"
                                                fullwidth="true"
                                                placeholder="Add Gift Card"
                                                id="Gift"
                                                {...getFieldProps('gift_card')}
                                                className="border-black placeholder-shown:border-black"
                                                />
                                            
                                                {Voucher && Voucher.status === 'success' ? (
                                                <span className="alert alert-success">{Voucher.message}</span>  
                                                ):(
                                                <Button onClick={handleSearch} className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block 
                                                    text-white capitalize text-sm px-8 py-3">Apply</Button> 
                                                )}
                                                {Voucher && Voucher.status === 'error' ? (
                                                <div className="te">
                                                    <span className="alert alert-danger">{Voucher.message}</span>  
                                                </div>
                                                ):('')}
                                            </div>
                                        </div>
                                     </div>
                                </>}
                                
                           </div>

                        </AccordionBody>
                      </Accordion>

                    </Fragment>

                    <hr/>

                {userId &&
                    <div className="complete-appointment">
                        <h2>Complete Appointment</h2>
                        <div className="overflow-x-auto relative">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <tbody>
                                    <tr className="bg-white dark:bg-gray-800">
                                        <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Price
                                            <br/>
                                            {paymentlabel &&
                                                <span>{paymentlabel}</span>
                                            }
                                        </th>
                                        <td className="py-4 text-right">
                                         {SelectedService ?(
                                          <>${SelectedService}</>
                                          ):( '$0.00'
                                         )} 
                                        </td>
                                    </tr>
                                    <tr className="bg-white dark:bg-gray-800">
                                        <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Additional Charges
                                            <br/><span>Cancellation Fee from previous appointment </span>
                                        </th>
                                        <td className="py-4 text-right">
                                            ${parseFloat(cancelledFees) ? parseFloat(cancelledFees).toFixed(2) : '0.00'}
                                        </td>
                                    </tr>
                                    <tr className="bg-white dark:bg-gray-800">
                                    
                                        <th scope="row" className="py-4 whitespace-nowrap dark:text-white">
                                            <div className="font-medium text-gray-900 font-bold mb-0">Applied Gift Card Amount</div>
                                             {Voucher && 
                                                <span variant="paragraph" className="text-sm text-black font-normal mb-0 test5">
                                                    {Voucher.code} (Remaining ${results.RemainingGiftamount?parseFloat(results.RemainingGiftamount).toFixed(2):'0.00'})
                                                </span>
                                            }
                                        </th>
                                        <td className="py-4 text-right">
                                             <span variant="paragraph" className="text-sm text-black font-normal mb-0 test4">
                                                 -${results.UsedGiftamount?parseFloat(results.UsedGiftamount).toFixed(2):"0.00"}
                                              </span>
                                          </td>
                                       </tr>

                                    <tr className="bg-white dark:bg-gray-800">
                                        <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Applied Credit
                                        </th>
                                        <td className="py-4 text-right">
                                             - ${parseFloat(results.AppliedCredit) > 0 ? parseFloat(results.AppliedCredit).toFixed(2):'0.00'}
                                        </td>
                                    </tr>
                                    {results.AppliedCredit > 0 ? (
                                        <tr className="bg-white dark:bg-gray-800">
                                            <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                Remaining Balance Payment Method
                                            </th>
                                            <td className="py-4 text-right">
                                                Credit Card                                                
                                            </td>
                                        </tr>
                                    ) : ( "" )
                                    }

                                    <tr>
                                        <td colspan="2">
                                         <hr/>
                                       </td>
                                    </tr>

                                    <tr className="bg-white dark:bg-gray-800">
                                        <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Total Amount:
                                        </th>
                                        <td className="py-4 text-right">
                                            ${parseFloat(results.TotalAmountService).toFixed(2)}
                                        </td>
                                    </tr>

                                    <tr className="bg-white dark:bg-gray-800">
                                        <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            Total Amount Due Now:
                                        </th>
                                        <td className="py-4 text-right">
                                            <div>
                                                ${results.TotalServicePrice > 0 ? parseFloat(results.TotalServicePrice).toFixed(2) :'0.00' }
                                            </div>
                                         </td>
                                    </tr>
                                  </tbody>
                            </table>
                        </div>
                        <div className="bottom-button-group flex flex-wrap justify-end">
                            <div className="col">
                                <button className="bg-black hover:bg-blue-900 active:bg-blue-900 focus:bg-blue-900 rounded-md shadow-none hidden lg:inline-block text-white capitalize text-sm px-8 py-3" type="submit">Book Appointment</button>
                            </div>
                        </div>
                    </div>
                }
                </form>
            </div>
        </>
    )
}
export default AppointmentForm;